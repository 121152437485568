<template>
  <div class="wrap1160 exhibit-wrap">
    <h1>{{ $t('MEMBER_REQUEST_CONFIRM_TITLE') }}</h1>
    <form id="entry-form">
      <div v-if="requestResultData.judge_status === 1 || requestResultData.judge_status === 2">
        <h2>{{ (requestResultData.request_category_name || '') + $t('REQUEST_RESULT_FROM_TITLE') }}</h2>
        <RequestResultDetailPreview
          :isPreview="true"
          :requestResultData="requestResultData"
          :judgeStatusPreview="true"
        />
      </div>

      <h2>{{ (requestResultData.request_category_name || '') + $t('REQUEST_DETAIL_FROM_TITLE') }}</h2>
      <div v-if="requestResultData.request_category_id !== '1'" class="mb-5">
        <div class="entry-formInner bgLGray">
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_EXHIBITION_DIVISION') }}</p>
            <div class="form-wrap-input">
              <p>{{ requestData.request_category_name || '' }}</p>
            </div>
          </div>
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_RESULT_ASSESSMENT_AMOUNT') }}</p>
            <div class="form-wrap-input">
              <p>{{ requestData.assessment_amount | number2string('', '円') }}</p>
            </div>
          </div>
          <div class="form-wrap" v-if="requestData.request_category_id === '4'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_POPUP_BID_DATETIME_FIELD') }}</p>
            <div class="form-wrap-input">
              <p>{{ requestData.start_datetime }} ~ {{ requestData.end_datetime }} から随時終了</p>
            </div>
          </div>
          <div class="form-wrap" v-if="requestData.request_category_id === '4'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_POPUP_EXHIBITION_NAME_FIELD') }}</p>
            <div class="form-wrap-input">
              <p>{{ requestData.exhibition_name }}</p>
            </div>
          </div>
          <div class="form-wrap" v-if="requestData.request_category_id === '4'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_POPUP_MAX_EXHIBITION_DATETIME_FIELD') }}</p>
            <div class="form-wrap-input" v-if="requestData.extend_flag === 1">
              あり
              <div>※最大{{ requestData.max_extend_datetime }}まで</div>
            </div>
            <div class="form-wrap-input" v-else>
              なし
            </div>
          </div>
          <div class="form-wrap" v-if="requestData.request_category_id !== '2'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_REQUEST_POST_CODE_LABEL') }}</p>
            <div class="form-wrap-input">
              <p>{{ requestData.post_code }}</p>
            </div>
          </div>
          <div class="form-wrap" v-if="requestData.request_category_id !== '2'">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_REQUEST_ADDRESS_LABEL') }}</p>
            <div class="form-wrap-input">
              <p>{{ requestData.address }}</p>
            </div>
          </div>
          <div class="form-wrap">
            <p class="form-wrap-tit">{{ $t('INSPECTION_AUCTION_COMMENT') }}</p>
            <div class="form-wrap-input" style="white-space: pre-line">
              {{ requestData.comment }}
            </div>
          </div>
        </div>
      </div>

      <RequestDetailPreview
        :isPreview="true"
        :manageNo="manageNo"
        :requestTitle="requestTitle"
        :comment="comment"
        :commonData="commonData"
        :identityFiles="identityFiles"
        :bodyTypes="bodyTypes"
        :constants="constants"
        :getPreviewData="getPreviewData"
        :imageItems="imageItems"
        :imageOptionals="imageOptionals"
        :documentFiles="documentFiles"
        :errors="errors"
      />
    </form>
  </div>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import {defineComponent} from '@vue/composition-api'
import RequestDetailPreview from './parts/RequestDetailPreview'
import RequestResultDetailPreview from './parts/RequestResultDetailPreview'
export default defineComponent({
  components : {
    RequestDetailPreview,
    RequestResultDetailPreview
  },
  mixins : [CommonFilters],
  props  : {
    isPreview : {
      type    : Boolean,
      default : false
    },
    manageNo : {
      type    : String,
      default : null
    },
    requestTitle : {
      type    : String,
      default : null
    },
    comment : {
      type    : String,
      default : null
    },
    commonData : {
      type    : Object,
      default : () => {}
    },
    identityFiles : {
      type    : Array,
      default : () => []
    },
    bodyTypes : {
      type    : Object,
      default : () => {}
    },
    constants : {
      type    : Object,
      default : () => {}
    },
    getPreviewData : {
      type    : Function,
      default : Object
    },
    imageItems : {
      type    : Array,
      default : () => []
    },
    imageOptionals : {
      type    : Array,
      default : () => []
    },
    documentFiles : {
      type    : Array,
      default : () => []
    },
    errors : {
      type    : Object,
      default : () => {}
    },
    requestResultData : {
      type    : Object,
      default : () => {}
    },
    judgeStatusPreview : {
      type    : Boolean,
      default : true
    },
    requestData : {
      type    : Object,
      default : () => {}
    }
  },
  computed : {
  },
  methods : {
  }
})
</script>

<style lang="scss" scoped>
.image-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
form .form-wrap {
  align-items: start !important;
}
.selection-txt {
  margin-left: 10px;
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss">
</style>
