<template>
<main id="main">
  <section id="exhibit" class="exhibit">
    <Preview
      :isPreview="true"
      :manageNo="manageNo"
      :requestTitle="requestTitle"
      :comment="comment"
      :commonData="commonData"
      :identityFiles="identityFiles"
      :bodyTypes="bodyTypes"
      :constants="constants"
      :getPreviewData="inspectionPreviewStore.getPreviewData"
      :imageItems="inspectionPreviewStore.itemImages"
      :imageOptionals="inspectionPreviewStore.optionalImages"
      :documentFiles="inspectionPreviewStore.documentFiles"
      :errors="errors"
      :requestResultData="requestResultData"
      :requestData="requestData"
    />
    <div class="btn-form text-center mt-3">
      <input
        type="button"
        class="btn-back"
        :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')"
        @click="$routerGo($define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)"
      >
    </div>
  </section>
</main>
</template>

<script>
import useInspectionPreview from '@/stores/useInspectionPreview'
import useLoading from '@/stores/useLoading'
import useRequestResultPreview from '@/stores/useRequestResultPreview'
import {defineComponent} from '@vue/composition-api'
import Preview from '../../components/Inspection/Preview'
export default defineComponent({
  setup() {
    const inspectionPreviewStore = useInspectionPreview()
    const resultPreviewStore = useRequestResultPreview()
    const loadingStore = useLoading()
    return {
      inspectionPreviewStore,
      resultPreviewStore,
      loadingStore
    }
  },
  components : {
    Preview,
  },
  data() {
    return {
      errors : {}
    }
  },
  computed : {
    manageNo() {
      return this.inspectionPreviewStore.manageNo
    },
    requestTitle() {
      return this.inspectionPreviewStore.requestTitle
    },
    comment() {
      return this.inspectionPreviewStore.comment
    },
    commonData() {
      return this.inspectionPreviewStore.commonData
    },
    constants() {
      return this.inspectionPreviewStore.constants
    },
    identityFiles() {
      return this.inspectionPreviewStore.identityFiles
    },
    bodyTypes() {
      return this.inspectionPreviewStore.bodyTypes
    },
    requestResultData() {
      return this.resultPreviewStore.requestResultData
    },
    requestData() {
      return this.inspectionPreviewStore.requestData
    }
  },
  mounted() {
    console.log('mounted')
    this.initScreen()
  },
  created() {
    console.log('created')
    this.inspectionPreviewStore.resetData()
  },
  methods : {
    initScreen() {
      this.loadingStore.setLoading(true)
      const itemRequestNo = this.$route.params ? this.$route.params.id : null
      if (itemRequestNo) {
        this.inspectionPreviewStore.memberCheck(itemRequestNo, this.$i18n.locale)
          .then(() => {
            this.inspectionPreviewStore.getConstants(itemRequestNo, this.$i18n.locale)
            this.inspectionPreviewStore.getRequestData(itemRequestNo, this.$i18n.locale)
            this.resultPreviewStore.getResult(itemRequestNo, this.$i18n.locale)
            this.loadingStore.setLoading(false)
          })
          .catch(error => {
            this.loadingStore.setLoading(false)
            this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
            this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
            this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
          })
      } else {
        this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_LIST_PAGE_NAME)
      }
    }
  },
  watch   : {
    '$route.params.id'(val) {
      console.log('new id: ', val)
      this.inspectionPreviewStore.resetData()
      this.initScreen()
    }
  }
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
